import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'

// Alert
import AlertService from '@/common/alert.service'

export default function useMusteris(props, context) {
  const musterisTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { label: 'Ad', key: 'sMusteriAdi', sortable: true },
    { label: 'Kod', key: 'sMusteriKodu', sortable: true },
    { label: 'Vergi No', key: 'sVergiNo', sortable: true },
    { label: 'Telefon', key: 'sMusteriTel', sortable: true },
    { label: 'Yetkili', key: 'sYetkiliAd', sortable: true },
    { label: 'Tip', key: 'pMusteriTipi', sortable: true },
  ]

  const perPage = ref(10)
  const toplamMusteri = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = computed({
    get() {
      return store.getters['yonetimMusteri/getMusteriLocalFilters'].sortBy
    },
    set(value) {
      store.commit('yonetimMusteri/setMusteriFilter', { key: 'sortBy', value })
    },
  })
  const isSortDirDesc = computed({
    get() {
      return store.getters['yonetimMusteri/getMusteriLocalFilters'].sortDesc
    },
    set(value) {
      store.commit('yonetimMusteri/setMusteriFilter', { key: 'sortDesc', value })
    },
  })

  const dataMeta = computed(() => {
    const localItemsCount = musterisTable.value ? musterisTable.value.localItems.length : 0
    return {
      from: currentPage.value === 1 ? 1 : perPage.value * (currentPage.value - 1) + 1,
      to: perPage.value * (currentPage.value) < localItemsCount ? perPage.value * (currentPage.value) : localItemsCount,
      of: toplamMusteri.value,
    }
  })

  const refetchData = () => {
    musterisTable.value.refresh()
  }

  watch([currentPage, perPage], () => {
    refetchData()
  })

  const fetchMusteris = () => {
    store.dispatch('yonetimMusteri/fetchMusteris', { })
  }

  const refreshMusterisFromApi = () => {
    store.dispatch('yonetimMusteri/refreshMusterisFromApi')
      .then(response => {
        if (response.statusCode === 200) {
          AlertService.success(context.root, response.message)
          fetchMusteris();
        } else {
          AlertService.error(context.root, response.message)
        }
      })
  }

  const getMusteris = computed(() => {
    const musteris = store.getters['yonetimMusteri/getMusteris']
    toplamMusteri.value = musteris.length
    return musteris
  })

  onMounted(fetchMusteris)

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveMusteriTipiVariant = role => {
    if (role === 'Müşteri') return 'primary'
    if (role === 'Müşteri_Tedarikçi') return 'warning'
    if (role === 'Tedarikçi') return 'success'
    return 'primary'
  }

  const resolveMusteriTipiIcon = role => {
    if (role === 'Müşteri') return 'UserIcon'
    if (role === 'Müşteri_Tedarikçi') return 'UserCheckIcon'
    if (role === 'Tedarikçi') return 'UserIcon'
    return 'UserIcon'
  }

  const resolveMusteriTipi = tip => {
    if (tip === 'Müşteri') return 'Müşteri'
    if (tip === 'Müşteri_Tedarikçi') return 'Müşteri Tedarikçi'
    if (tip === 'Tedarikçi') return 'Tedarikçi'
    return 'primary'
  }

  return {
    fetchMusteris,
    refreshMusterisFromApi,
    getMusteris,
    tableColumns,
    perPage,
    currentPage,
    toplamMusteri,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    musterisTable,

    resolveMusteriTipiVariant,
    resolveMusteriTipiIcon,
    resolveMusteriTipi,
  }
}
