<template>
  <div>
    <!-- Buttons -->
    <crud-button 
      :showAddNew="false" 
      :showRefresh="true" 
      :onClickList="fetchMusteris"
      :onClickRefresh="refreshMusterisFromApi" />

    <!-- Filters -->
    <musteri-filters />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>{{ $t('tane göster') }}</label>
        </b-col>
      </b-row>
      </div>

      <b-table
        ref="musterisTable"
        class="position-relative"
        :items="getMusteris"
        :per-page="perPage"
        :current-page="currentPage"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Hiçbir eşleşen kayıt bulunamadı"
        :sort-desc.sync="isSortDirDesc">

        <!-- Column: Musteri -->
        <template #cell(sMusteriAdi)="data">
          <b-media vertical-align="center">
            <b-link
              class="font-weight-bold d-block text-nowrap" 
              :to="{ name: 'manage-musteri-save', params: { id: data.item.id } }">
              <span v-if="data.item.sMusteriAdi.length <= 30">
                {{ data.item.sMusteriAdi }}
              </span> 
              <span v-else
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.v-primary
                :title="data.item.sMusteriAdi">
                {{ data.item.sMusteriAdi.substring(0,30) + '...' }}
              </span> 
            </b-link>
          </b-media>
        </template>

        <!-- Column: Kodu -->
        <template #cell(sMusteriKodu)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sMusteriKodu }}</span>
          </div>
        </template>

        <!-- Column: Vergi -->
        <template #cell(sVergiNo)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sVergiNo }}</span>
          </div>
        </template>

        <!-- Column: Telefon -->
        <template #cell(sMusteriTel)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sMusteriTel }}</span>
          </div>
        </template>

        <!-- Column: Yetkili -->
        <template #cell(sYetkiliAd)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.sYetkiliAd + ' ' + data.item.sYetkiliSoyad }}</span>
          </div>
        </template>

        <!-- Column: Tipi -->
        <template #cell(pMusteriTipi)="data">
          <div class="text-nowrap">
            <feather-icon
              :icon="resolveMusteriTipiIcon(data.item.pMusteriTipi)"
              size="18"
              class="mr-50"
              :class="`text-${resolveMusteriTipiVariant(data.item.pMusteriTipi)}`"
            />
            <span class="align-text-top text-capitalize">{{ resolveMusteriTipi(data.item.pMusteriTipi) }}</span>
          </div>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">{{ dataMeta.of }} öğeden {{ dataMeta.from }} - {{ dataMeta.to }} arası gösteriliyor</span>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12" sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end">

            <b-pagination
              v-model="currentPage"
              :total-rows="toplamMusteri"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  VBTooltip, 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import MusteriFilters from './MusteriFilters.vue'
import userMusteris from './userMusteris'

export default {
  components: {
    MusteriFilters,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  setup(props, context) {
    const {
      fetchMusteris,
      refreshMusterisFromApi,
      getMusteris,
      tableColumns,
      perPage,
      currentPage,
      toplamMusteri,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      musterisTable,

      resolveMusteriTipiVariant,
      resolveMusteriTipiIcon,
      resolveMusteriTipi,
    } = userMusteris(props, context)

    return {
      fetchMusteris,
      refreshMusterisFromApi,
      getMusteris,
      tableColumns,
      perPage,
      currentPage,
      toplamMusteri,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      musterisTable,

      // UI
      resolveMusteriTipiVariant,
      resolveMusteriTipiIcon,
      resolveMusteriTipi,
    }
  },
}
</script>
